import React from 'react';
import '../stylesheet/contactus.css'

const Contact = () => {
  return (
    <div>
  <div className='commonBanner'>Contact Us</div>

    <div className="contact-us">

      <div className='contact-details container'>
    <section className="contact-info">
      <h1>Get In Touch</h1>
      <div className="info-container">
        <div className="info-item">
          <h3>Address</h3>
          <p>189, Gnanagiri Rd, near palaniyandaver theatre, Kamarajapuram Colony, Sivakasi, Tamil Nadu 626189</p>
        </div>
        <div className="info-item">
          <h3>Phone</h3>
          <p>04562 227 980</p>
        </div>
        <div className="info-item">
          <h3>Email</h3>
          <p>contact@cinevijayammess.com</p>
        </div>
      </div>
    </section>

    <section className="contact-form">
      <form>
        <input type="text" placeholder="Name" required />
        <input type="email" placeholder="Email" required />
        <textarea placeholder="Your Message" rows="5" required></textarea>
        <button type="submit">Submit</button>
      </form>
    </section>
    </div>

    <section className="map">
      <div className="map-container">

        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15742.40581145076!2d77.802631!3d9.4563014!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06cef0819e033b%3A0x3a8ed8833514a763!2sCine%20Vijayam%20Mess!5e0!3m2!1sen!2sin!4v1731295018634!5m2!1sen!2sin"
          width="100%"
          height="400"
          allowFullScreen=""
          loading="lazy"
          title="Google Map"></iframe>
      </div>
    </section>
  </div>
  </div>
  )
  ;
};

export default Contact;
