import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../additional/bancarousel.css"


const BanCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const cards = [
    { id: 1, name: "BRIYANI", category: "../product/category/briyani.png", background: "../product/category/briyani_bg.jpg" },
    { id: 2, name: "PAROTTA", category: "../product/category/parotta.png", background: "../product/category/parotta_bg.jpg" },
    { id: 3, name: "CHICKEN", category: "../product/category/chicken.png", background: "../product/category/chicken_bg.jpg" },
    { id: 4, name: "FRIED RICE", category: "../product/category/friedrice.png", background: "../product/category/friedrice_bg.jpg" },
    { id: 5, name: "GRAVY", category: "../product/category/gravy.png", background: "../product/category/gravy_bg.jpg" },
  ];

  const handleBeforeChange = (oldIndex, newIndex) => {
    setActiveIndex(newIndex);
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "60px",
    beforeChange: handleBeforeChange,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "20px",
        },
      },
    ],
  };

        return (
          <div className="bannersec">
            <div className="carousel-section">
              <div
                className="carousel-bg"
                style={{
                  background: `url(${cards[activeIndex].background})`,
                }}
              ></div>

              <div className="runDiv">
                <div className="runText">
                <span>HOTEL CINE VIJAYAM MESS &nbsp; HOTEL CINE VIJAYAM MESS &nbsp;</span>
                <span>HOTEL CINE VIJAYAM MESS &nbsp; HOTEL CINE VIJAYAM MESS &nbsp;</span>
                </div>
              </div>

            <div className="ban-main">
              <div className="ban-cont">
                <h1 className="feel">Feel the</h1>
                <h1 className="taste">Best Taste Ever</h1>
                <p>
                Taste the Goodness, Feel the Difference,
                Your Journey to Wellness Starts Here.
                </p>
                <button>Contact Us</button>
              </div>
                

              <Slider className="banCategory" {...settings}>
                {cards.map((card, index) => (
                  <div key={card.id} className={index === activeIndex ? "active" : ""}>
                    <div className="card">
                      <img className="item" src={card.category} alt={`Card ${card.id}`} />
                      <h1>{card.name}</h1>
                      <img src="../icons/arrow.svg" className="icon" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            </div>
            </div>
          );
        };
        
        export default BanCarousel;