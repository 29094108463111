import React from 'react';
import '../Footer.css'; // Include your CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="social-media">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <img src="./icons/facebook.svg" alt="Facebook" />
          </a>
          <a href="https://whatsapp.com" target="_blank" rel="noopener noreferrer">
            <img src="./icons/whatsapp.svg" alt="Twitter" />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <img src="./icons/instagram.svg" alt="Instagram" />
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <img src="./icons/linkedin.svg" alt="LinkedIn" />
          </a>
          <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
            <img src="./icons/youtube.svg" alt="YouTube" />
          </a>
        </div>

        <div className="contact">
          <p>Address: 189, Gnanagiri Rd, near palaniyandaver theatre, <br/>Kamarajapuram Colony, Sivakasi, Tamil Nadu 626189</p>
          <p>Email: info@cinevijayammess.com</p>
          <p>Phone: (04562)227-980</p>
        </div>

        <div className="copyright">
          <p>&copy; {new Date().getFullYear()} Hotel Cine Vijayam. All rights reserved.</p>
          <p>Developed by: <a href="https://technisite.in" target='_blank'>TECHNISITE SOLUTION</a></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
