import React from 'react';
import './Cart.css';

const Cart = ({ cartItems, removeFromCart, isCartVisible, toggleCartVisibility }) => {
  return (
    <div className={`cart-sidebar ${isCartVisible ? 'visible' : ''}`}>
      <div className="cart-header">
        <h2>Shopping Cart</h2>
        <button className="close-btn" onClick={toggleCartVisibility}>X</button>
      </div>
      {cartItems.length === 0 ? (
        <p>No items in cart</p>
      ) : (
        <ul>
          {cartItems.map((item, index) => (
            <li key={index}>
              {item.name}
              <button onClick={() => removeFromCart(item)} className="remove-btn">
                Remove
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Cart;
