import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '../Header.css';
import '../index.css';

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`header ${isSticky ? 'sticky' : ''}`}>
      <nav className="navbar container">
        <img src='./logo.png' alt="Logo" />
        <div className='menubar'>
        <button className="hamburger-icon" onClick={toggleMobileMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" width="30px" height="30px">
              <path d="M3 6h18v2H3zm0 5h18v2H3zm0 5h18v2H3z" />
            </svg>
          </button>
          <ul className="nav-list">
            <li><NavLink to="/" end>Home</NavLink></li>
            <li><NavLink to="/about">About</NavLink></li>
            <li><NavLink to="/catering">Our Catering</NavLink></li>
            <li><NavLink to="/contact">Contact</NavLink></li>
          </ul>

          {/* Hamburger Icon for Mobile Menu */}

        </div>
      </nav>

      {/* Mobile Menu Overlay */}
      <div className={`mobile-menu ${isMobileMenuOpen ? 'show' : ''}`}>
        <NavLink to="/" onClick={toggleMobileMenu}>Home</NavLink>
        <NavLink to="/about" onClick={toggleMobileMenu}>About</NavLink>
        <NavLink to="/catering" onClick={toggleMobileMenu}>Our Catering</NavLink>
        <NavLink to="/contact" onClick={toggleMobileMenu}>Contact</NavLink>
        <NavLink to="/menus" onClick={toggleMobileMenu}>Menus</NavLink>
      </div>
    </header>
  );
};

export default Header;
