import React from 'react';
import Hotelfood from '../additional/Hotelfood';

const Catering = () => {
  return <div>
    <div className='commonBanner'>Our Catering</div>
    <Hotelfood 
      
    showAddToCartButton={true}
    />
  </div>;
};

export default Catering;
