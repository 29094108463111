import React, { useState, useEffect } from 'react';
import Navbar from '../additional/Navbar.js';
import ProductList from '../additional/Productlist.js';
import Cart from '../additional/Cart.js';
import Pagination from '../additional/Pagination.js';
import '../additional/Hotelfood.css';
import SearchBar from '../additional/Searchbar.js';

const Hotelfood = ({ showAddToCartButton }) => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isCartVisible, setIsCartVisible] = useState(false);

  const categories = ['All', 'Briyani', 'Parotta', 'Chicken', 'Fried Rice', 'Shawarma', 'Gravy'];

  useEffect(() => {
    fetch('/products.json')
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  const filteredProducts = products.filter(product => 
    (selectedCategory === 'All' || product.category === selectedCategory) &&
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const itemsPerPage = 12;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); 
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1); 
  };

  const addToCart = (product) => {
    setCartItems([...cartItems, product]);
    setIsCartVisible(true);
  };

  const removeFromCart = (product) => {
    const updatedCart = cartItems.filter(item => item.id !== product.id);
    setCartItems(updatedCart);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const toggleCartVisibility = () => {
    setIsCartVisible(!isCartVisible);
  };

  return (
    <div className="hotelfood container">
      <div className='foodheading'>
      <h2 className='mainfont'>Our Hotel Foods</h2>
      <SearchBar onSearch={handleSearch} />
      </div>

      <Navbar 
        categories={categories} 
        selectedCategory={selectedCategory}
        onCategoryChange={handleCategoryChange} 
      />
      <ProductList 
        products={currentProducts} 
        addToCart={addToCart} 
        showAddToCartButton={showAddToCartButton}
      />
      <Pagination 
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

      <button className="cart-icon" onClick={toggleCartVisibility}>
        🛒
      </button>
      <Cart 
        cartItems={cartItems} 
        removeFromCart={removeFromCart} 
        isCartVisible={isCartVisible} 
        toggleCartVisibility={toggleCartVisibility} 
      />
    </div>
  );
};

export default Hotelfood;
