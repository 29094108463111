import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../additional/catering.css'; 

const data = [
  { id: 1, name: "Chicken Briyani", category: "Briyani", image: "./product/list/1.jpg", price: 150 },
  { id: 2, name: "Single Parotta", category: "Parotta", image: "./product/list/2.jpg", price: 50 },
  { id: 3, name: "Babeque Chicken", category: "Chicken", image: "./product/list/3.jpg", price: 200 },
  { id: 4, name: "Veg Rice", category: "Fried Rice", image: "./product/list/4.jpg", price: 120 },
  { id: 5, name: "BBQ Shawarma", category: "Shawarma", image: "./product/list/5.jpg", price: 100 },
  { id: 6, name: "Mutton Briyani", category: "Briyani", image: "./product/list/6.jpg", price: 250 },
  { id: 7, name: "Kothu Parotta", category: "Parotta", image: "./product/list/7.jpg", price: 180 },
  { id: 8, name: "Naatu Kolambu", category: "Gravy", image: "./product/list/8.jpg", price: 130 },
  { id: 9, name: "Plate Shawarma", category: "Shawarma", image: "./product/list/9.jpg", price: 90 },
  { id: 10, name: "Pepper Chicken", category: "Gravy", image: "./product/list/10.jpg", price: 220 },
];

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
  };

  return (
    <div className='catering'>
    <div className="carousel-container">
      <div className="carousel-background"
                      style={{
                        backgroundImage: "linear-gradient(45deg, #00000080, #00000090), url('product/offers/catering_ban.jpg')"
                      }}
      >
        <div className='details'>
          <h1 style={{color:"#F6951E"}}>Cheap and Best Catering Orders</h1>
          <h1>To Order Call or Whatsap to</h1>
          <h2><img src='../icons/call.svg' />+91 63817 17887</h2>
        </div>
        <div className='slider_sect'>
        <Slider {...settings}>
          {data.map(item => (
            <div className="card" key={item.id}>
              <img src={item.image} alt={item.name} />
              <h3>{item.name}</h3>
              <p>Category: {item.category}</p>
              <p>Price: ₹{item.price}</p>
            </div>
          ))}
        </Slider>
        <img className='cate_ass' src='../icons/catering_ass.png'/>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Carousel;
