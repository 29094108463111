import React from 'react';
import '../stylesheet/about.css'

const About = () => {
  return (
    <div className="about-us">
      <div className='commonBanner'>About Us</div>
      <section className="hero container">
        <h1>Welcome to Hotel Cine Vijayam</h1>
        <p>Experience the taste of traditional flavors in every bite.</p>
      </section>

      <section className="mission container">
        <div className="content">
          <div className='vijayamess'><img src="../restaurant.png" /></div>
         <div className='vijayamessabt'> 
          <h2>Traditional & <br/> Modern Food</h2>
          <p>At Hotel Cine Vijayam, we are dedicated to bringing the finest, authentic Indian cuisine to our guests.
            Our mission is to create unforgettable dining experiences that bring people together and celebrate the rich culinary heritage of India.</p>
            <button>Our Menu</button>
            </div>
            <div className='vijayamess'><img src="../chef.jpg" /></div>
        </div>
      </section>

      <section className="know container">
        <div><img src='../about.jpg'/></div>
        <div className='knowmore'>
          <div >
          <h3>OUR STORY</h3>
          <h1>Where Great Food meets Good Vibes</h1>
          <p>Cine vijayamess was started in 2000 by "Person Name". After days it became everyones favourate restaurant of all time.
            Where every meal is a story of flavor, crafted with passion and served with warmth.</p>
          </div>
          <div className='knowcontent'>
            <div>
              <h1>10+</h1>
              <h3>Experience</h3>
            </div>
            <div>
              <h1>30+</h1>
              <h3>Employees</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="chef-spotlight container">
        <h2>Meet Our Chef</h2>
        <div className="chef-container">
          <img src="chef.jpg" alt="Chef" />
          <p>Chef Arjun Kumar brings decades of culinary expertise and passion to every dish. Known for his innovative techniques and respect for tradition, Chef Arjun is the heart and soul of Hotel Cine Vijayam’s kitchen.</p>
        </div>
      </section>

      <section class="reviews-section container">
    <h2 class="mainfont">What Our Customers Say</h2>
    <div class="reviews-container">
      <div class="review-card">
        <img src="../icons/customer1.jpg" alt="Customer 1" class="review-photo" />
        <h3 class="review-name">John Doe</h3>
        <p class="review-text">"Fantastic food and top-notch service! I had the best dining experience here. Highly recommend!"</p>
        <div class="review-stars">⭐⭐⭐⭐⭐</div>
      </div>


      <div class="review-card">
        <img src="../icons/customer2.jpg" alt="Customer 2" class="review-photo" />
        <h3 class="review-name">Sarah Smith</h3>
        <p class="review-text">"The ambiance was great, and the food was beyond delicious. I'll be coming back for sure!"</p>
        <div class="review-stars">⭐⭐⭐⭐⭐</div>
      </div>


      <div class="review-card">
        <img src="../icons/customer3.jpg" alt="Customer 3" class="review-photo" />
        <h3 class="review-name">Mike Johnson</h3>
        <p class="review-text">"An amazing experience! Every dish was flavorful and fresh. Service was excellent!"</p>
        <div class="review-stars">⭐⭐⭐⭐⭐</div>
      </div>

      <div class="review-card">
        <img src="../icons/customer4.jpg" alt="Customer 4" class="review-photo" />
        <h3 class="review-name">Emily Clark</h3>
        <p class="review-text">"Highly recommended! A delightful culinary journey that exceeded my expectations."</p>
        <div class="review-stars">⭐⭐⭐⭐⭐</div>
      </div>
    </div>
  </section>
    </div>
  );
};

export default About;
