import React from 'react';
import '../index.css'
import '../stylesheet/home.css'
import BanCarousel from '../additional/bancarousel';
import Hotelfood from '../additional/Hotelfood';
import Catering from '../additional/Catering';

const Home = () => {
  return <div>
    <div>
      <BanCarousel/>

      <div className='penguin container'>
        <div className='workers'>
          <img src='../icons/chef.png' />
          <h2>Chef’s Mastery</h2>
          <p>Crafted with passion, every dish tells a story. Our chef brings flavors to life with expertise and love for the craft.</p>
        </div>
        <div className='workers'>
          <img src='../icons/delivery.png' />
          <h2>Chef’s Mastery</h2>
          <p>Crafted with passion, every dish tells a story. Our chef brings flavors to life with expertise and love for the craft.</p>
        </div>
        <div className='workers'>
          <img src='../icons/serving.png' />
          <h2>Chef’s Mastery</h2>
          <p>Crafted with passion, every dish tells a story. Our chef brings flavors to life with expertise and love for the craft.</p>
        </div>
      </div>

      <Hotelfood />

      <div className='offerSection container'>
        <div className='offers'
        style={{
          backgroundImage: "linear-gradient(45deg, #000000, #00000010), url('product/offers/offer_1.png')"
        }}
        >
          <h4>Combo Offer</h4>
          <h2>Buy 10 Parotta with Chicken Gravy</h2>
          <p>Get Ready to sink into the best Taste Ever</p>
          <h1>₹200</h1>
          <button>Order Now</button>
        </div>
        <div className='offers'
                style={{
                  backgroundImage: "linear-gradient(45deg, #000000, #00000090, #00000010), url('product/offers/offer_2.png')"
                }}
        >
          <h4>Combo Offer</h4>
          <h2>Buy 10 Parotta with Chicken Gravy</h2>
          <p>Get Ready to sink into the best Taste Ever</p>
          <h1>₹200</h1>
          <button>Order Now</button>
        </div>
      </div>
      <Catering />
 
    <section class="reviews-section">
    <h2 class="mainfont">What Our Customers Say</h2>
    <div class="reviews-container">
      <div class="review-card">
        <img src="../icons/customer1.jpg" alt="Customer 1" class="review-photo" />
        <h3 class="review-name">John Doe</h3>
        <p class="review-text">"Fantastic food and top-notch service! I had the best dining experience here. Highly recommend!"</p>
        <div class="review-stars">⭐⭐⭐⭐⭐</div>
      </div>


      <div class="review-card">
        <img src="../icons/customer2.jpg" alt="Customer 2" class="review-photo" />
        <h3 class="review-name">Sarah Smith</h3>
        <p class="review-text">"The ambiance was great, and the food was beyond delicious. I'll be coming back for sure!"</p>
        <div class="review-stars">⭐⭐⭐⭐⭐</div>
      </div>


      <div class="review-card">
        <img src="../icons/customer3.jpg" alt="Customer 3" class="review-photo" />
        <h3 class="review-name">Mike Johnson</h3>
        <p class="review-text">"An amazing experience! Every dish was flavorful and fresh. Service was excellent!"</p>
        <div class="review-stars">⭐⭐⭐⭐⭐</div>
      </div>

      <div class="review-card">
        <img src="../icons/customer4.jpg" alt="Customer 4" class="review-photo" />
        <h3 class="review-name">Emily Clark</h3>
        <p class="review-text">"Highly recommended! A delightful culinary journey that exceeded my expectations."</p>
        <div class="review-stars">⭐⭐⭐⭐⭐</div>
      </div>
    </div>
  </section>
    </div>
  </div>;
};

export default Home;
