import React from 'react';

const Navbar = ({ categories, selectedCategory, onCategoryChange }) => {
  return (
    <div className="navbar">
      {categories.map((category) => (
        <button
          key={category}
          className={selectedCategory === category ? 'active' : ''}
          onClick={() => onCategoryChange(category)}
        >
          {category}
        </button>
      ))}
    </div>
  );
};

export default Navbar;
